import Cookies from 'js-cookie';

const utmParams = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term'
];

export const getUTMParams = () => {
  const utm = {};
  utmParams.forEach(i => {
    utm[i] = Cookies.get(i);
  });

  return utm;
};
