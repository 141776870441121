import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';
import styled from 'styled-components/macro';

import LongArrowRight from 'components/Icon/LongArrowRight';
import { Wrapper } from 'shared/Blocks';
import Layout from 'shared/MainLayout';
import RedButton from 'shared/MyButton/RedButton';

import FTDolRegister from '../../shared/DolRegister/FTDolRegister';
import { getResultUrl } from '../../utils/getFreeIeltsTestUrl';

const ContactUs = ({ location: { state } }) => {
  useEffect(() => {
    if (!state || !state.testType) {
      navigate('/');
    }
  }, [state]);
  const { testType } = state || {};

  return (
    <Layout noFooter noMenu hideSideCTA>
      <PageWrapper>
        <DolIntroWrapper>
          <ContactUsWrapper>
            <Title>
              5 điểm làm DOL trở thành trung tâm IELTS được giới{' '}
              <span className="em">chuyên môn đánh giá cao nhất</span> Sài Gòn
            </Title>
            <div>
              <PointList />
              <Link href="/" target="_blank">
                <LongArrowRight />
                <span>Xem chi tiết: dolenglish.vn</span>
              </Link>
            </div>
          </ContactUsWrapper>
        </DolIntroWrapper>
        <FTDolRegister
          onSubmitSuccess={() =>
            navigate(getResultUrl(testType), { replace: true })
          }
        />
      </PageWrapper>
    </Layout>
  );
};

const DolIntroWrapper = styled(Wrapper)`
  @media screen and (min-width: 769px) {
    padding: 60px 0;
  }
`;

const Link = styled(RedButton)`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 34px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

  span {
    margin-left: 5px;
  }
`;
const PageWrapper = styled.div``;
const ContactUsWrapper = styled.div`
  @media screen and (min-width: 769px) {
    display: grid;
    grid-template-columns: 3fr 7fr;
    gap: 5%;
  }
  @media screen and (max-width: 766px) {
    display: grid;
    grid-gap: 15px;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  color: #2d3742;
  max-width: 265px;

  .em {
    color: #d14242;
  }

  @media screen and (min-width: 769px) {
    font-size: 32px;
  }
`;

ContactUs.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({ testType: PropTypes.string })
  })
};

ContactUs.defaultProps = {};

export default memo(ContactUs);

//=================================================================================
// Point & Point List
//=================================================================================

const strings = [
  'Linearthinking - Hệ phương pháp Tiếng Anh Tư Duy dành cho Người Việt - được sáng tạo bởi anh Lực.',
  'Đội ngũ giáo viên profile "rất gì và này nọ" cộng sự tận tâm và khả năng sư phạm tốt.',
  'Là trung tâm đầu tiên và duy nhất tại thời điểm hiện tại áp dụng Công Nghệ và Trí Tuệ Nhân Tạo vào việc luyện tập ở nhà.',
  'Tình cảm học viên quá nhiều, những reviews 8 cây số mà không đâu có cả.',
  'Thành tích học viên đáng nể, nhiều 8.5 8.0 7.5 7.0 và những màn tăng điểm thần tốc bằng tư duy.'
];

const PointList = React.memo(() => {
  const [activeIdx, setActiveIdx] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      const nextIndex = (activeIdx + 1) % strings.length;
      setActiveIdx(nextIndex);
    }, 3000);

    return () => {
      clearInterval(id);
    };
  }, [activeIdx]);

  return strings.map((str, idx) => (
    <Point key={idx} order={idx + 1} content={str} active={activeIdx === idx} />
  ));
});

const Point = ({ order, content, active }) => {
  return (
    <PointWrapper>
      <PointOrder className={active ? 'active' : ''}>
        {(order + '').length > 9 ? order : `0${order}`}
      </PointOrder>
      <PointContent>{content}</PointContent>
    </PointWrapper>
  );
};

Point.propTypes = {
  order: PropTypes.number,
  content: PropTypes.string,
  active: PropTypes.bool
};

const PointContent = styled.div`
  font-size: 18px;
  color: #2d3742;

  @media screen and (min-width: 769px) {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
`;

const PointOrder = styled.div`
  font-size: 18px;
  text-align: center;
  border: 1px solid #dfe1e6;
  color: #2d3742;
  background: #fff;
  transition: 500ms;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background: #d14242;
    border: 1px solid #d14242;
    color: #ffffff;
    line-height: 40px;
  }

  @media screen and (min-width: 769px) {
    font-weight: bold;
    font-size: 24px;
  }
`;

const PointWrapper = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 48px 1fr;
  margin-bottom: 12px;

  @media screen and (min-width: 769px) {
    margin-bottom: 24px;
  }
`;
