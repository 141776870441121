import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Error = styled.p`
  color: #d14242;
  margin-bottom: 0.5em;
`;

const ErrorMessage = props => {
  const { msg } = props;
  if (msg) {
    return <Error>{msg}</Error>;
  }
  return null;
};

ErrorMessage.propTypes = {
  msg: PropTypes.string.isRequired
};

export default ErrorMessage;
