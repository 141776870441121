export const FREE_IELTS_TEST = '/free-ielts-test';

export const getWelcomeUrl = type =>
  `${FREE_IELTS_TEST}/${type.toLowerCase().trim()}`;
export const getDoTestUrl = type =>
  `${FREE_IELTS_TEST}/${type.toLowerCase().trim()}/do-test`;
export const getEvaluationUrl = type =>
  `${FREE_IELTS_TEST}/${type.toLowerCase().trim()}/evaluation`;
export const getResultUrl = type =>
  `${FREE_IELTS_TEST}/${type.toLowerCase().trim()}/result`;
export const getContactUsUrl = () => `${FREE_IELTS_TEST}/contact-us`;
