import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import { STUDENT_REGISTRATION_URL } from 'apis/baseURL';
import { getUTMParams } from 'utils/utm';

import { LEAD_EVENT, track } from '../../../../utils/facebook/facebookPixel';
import { withDolTheme } from '../../../../utils/materialTheme';
import { getSourceFromURL } from '../../../../utils/urlClassifier';
import { UserTracking } from '../../../../utils/userTracking';
import InputText from '../../../MyInput';
import { CustomRedBtn } from '../DolRegisterMsg';
import ErrorMessage from './ErrorMsg';
import SelectBox from './SelectBox';

const scrollToView = element => {
  element.scrollIntoView({
    block: 'center'
  });
};

const dolPurposeToLearnOptions = [
  { key: 'Du học', value: 'Du học' },
  { key: 'Định cư', value: 'Định cư' },
  { key: 'Việc làm', value: 'Việc làm' },
  { key: 'Tốt nghiệp', value: 'Tốt nghiệp' }
];

const dolTargetScoreOptions = [
  { key: '5.0', value: '5.0' },
  { key: '6.5', value: '6.5' },
  { key: '7.5+', value: '7.5+' }
];

const Main = styled.form`
  min-height: 320px;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InputItem = styled.div`
  margin-bottom: 0.5em;

  & .label {
    color: black;
  }
`;

const GroupInput = styled.div`
  width: 100%;
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
`;

const DolCheckBox = styled(withDolTheme(Checkbox))``;

const defaultErrorMessage = {
  errorNameMessage: '',
  errorPurposeToLearn: '',
  errorTargetScore: '',
  errorEmailMessage: '',
  errorPhoneMessage: ''
};

const initialState = {
  showForm: false,
  isSubmitted: false,
  name: '',
  email: '',
  phoneNumber: '',
  purposeToLearn: '',
  targetScore: null,
  needConsultant: true,
  registrationType: {
    key: 'Học thử 1 buổi miễn phí'
  },
  ...defaultErrorMessage
};

class FTRegisterForm extends React.PureComponent {
  constructor() {
    super();
    this.input = {
      firstName: React.createRef(),
      lastName: React.createRef(),
      email: React.createRef(),
      phoneNumber: React.createRef(),
      purposeToLearn: React.createRef(),
      targetScore: React.createRef(),
      availableTime: React.createRef()
    };
  }

  state = {
    ...initialState
  };

  setFirstName = e => {
    const { value } = e.target;
    this.setState({ firstName: value, errorFirstNameMessage: '' });
  };

  setLastName = e => {
    const { value } = e.target;
    this.setState({ lastName: value, errorLastNameMessage: '' });
  };

  setPurposeToLearn = value => {
    this.setState({ purposeToLearn: value, errorPurposeToLearn: '' });
  };

  setTargetScore = value => {
    this.setState({ targetScore: value, errorTargetScore: '' });
  };

  setEmail = e => {
    const { value } = e.target;
    this.setState({ email: value, errorEmailMessage: '' });
  };

  setPhoneNumber = e => {
    const { value } = e.target;
    this.setState({
      phoneNumber: value,
      errorPhoneMessage: '',
      errorInvalidPhoneFormat: ''
    });
  };

  setNeedConsultant = event => {
    this.setState({
      needConsultant: event.target.checked
    });
  };

  isValid = () => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      purposeToLearn,
      targetScore
    } = this.state;
    const defaultErrors = Object.assign({}, defaultErrorMessage);
    const emailRegex = new RegExp(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    const phoneNumberRegex = /^[0-9]{10,11}$/;
    // const scoreRegex = /^[0-9](\.[0-9]{1})?$/;
    const errorRequiredOption =
      'Đây là thông tin bắt buộc. Bạn hãy chọn 1 option nhé.';
    const errorRequiredField =
      'Đây là thông tin bắt buộc. Bạn hãy điền vào nhé.';
    const errorInvalidPhoneFormat = 'Format số điện thoại chưa đúng.';
    const errorInvalidEmailFormat = 'Format email chưa đúng.';
    // const errorInvalidTargetScore = 'Format điểm chưa đúng.';

    let allValidated = true;

    this.setState(defaultErrorMessage);

    if (!firstName) {
      defaultErrors.errorFirstNameMessage = errorRequiredField;
      if (allValidated) {
        this.input.firstName.current.focus();
      }
      allValidated = false;
    }

    if (!lastName) {
      defaultErrors.errorLastNameMessage = errorRequiredField;
      if (allValidated) {
        this.input.lastName.current.focus();
      }
      allValidated = false;
    }

    if (!email) {
      defaultErrors.errorEmailMessage = errorRequiredField;
      if (allValidated) {
        this.input.email.current.focus();
      }
      allValidated = false;
    } else if (!emailRegex.test(email.toLowerCase())) {
      defaultErrors.errorEmailMessage = errorInvalidEmailFormat;
      if (allValidated) {
        this.input.email.current.focus();
      }
      allValidated = false;
    }

    if (!phoneNumber) {
      defaultErrors.errorPhoneMessage = errorInvalidPhoneFormat;
      if (allValidated) {
        this.input.phoneNumber.current.focus();
      }
      allValidated = false;
    }

    if (!phoneNumberRegex.test(phoneNumber)) {
      defaultErrors.errorPhoneMessage = errorInvalidPhoneFormat;
      if (allValidated) {
        this.input.phoneNumber.current.focus();
      }
      allValidated = false;
    }

    if (!purposeToLearn) {
      defaultErrors.errorPurposeToLearn = errorRequiredOption;
      if (allValidated) {
        scrollToView(this.input.purposeToLearn.current);
      }
      allValidated = false;
    }

    if (!targetScore) {
      defaultErrors.errorTargetScore = errorRequiredOption;
      if (allValidated) {
        scrollToView(this.input.targetScore.current);
      }
      allValidated = false;
    }

    this.setState({
      ...defaultErrors
    });
    return allValidated;
  };

  submit = e => {
    e.preventDefault();

    if (!this.isValid()) {
      return;
    }

    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      purposeToLearn,
      targetScore,
      needConsultant
    } = this.state;
    const { onSubmitSuccess } = this.props;
    const utm = getUTMParams();
    const payload = {
      type: 'DOL_ENGLISH',
      firstName,
      lastName,
      email,
      phoneNumber,
      needConsultant,
      purposeToLearn: purposeToLearn.key,
      targetScore: targetScore.key,
      // registrationType: registrationType.key,
      referer: getSourceFromURL(UserTracking.getCurrentLocation()),
      sourceUrl: `${window.location.href}#${JSON.stringify(utm)}#`,
      utm
    };

    Promise.all([
      // submit(objectMapper(payload, registrationFTToHubspotFormMapping)),
      fetch(STUDENT_REGISTRATION_URL, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
    ])
      .then(() => {
        track(LEAD_EVENT);
        if (onSubmitSuccess) {
          onSubmitSuccess(payload);
        } else {
          navigate('/thank-you');
        }
      })
      .catch(() => {
        this.setState({
          errorMessage: 'Hệ thống gặp chút sự cố. Bạn hãy thử lại sau nhé.'
        });
      });
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      purposeToLearn,
      targetScore,
      needConsultant,

      // Error messages
      errorMessage,
      errorFirstNameMessage,
      errorLastNameMessage,
      errorEmailMessage,
      errorPhoneMessage,
      errorPurposeToLearn,
      errorTargetScore
    } = this.state;

    return (
      <Main onSubmit={this.submit}>
        <GroupInput>
          <InputItem>
            <InputText
              label="Họ"
              full
              ref={this.input.lastName}
              value={lastName}
              onChange={this.setLastName}
              error={errorLastNameMessage}
              message={errorLastNameMessage}
            />
          </InputItem>
          <InputItem>
            <InputText
              label="Tên"
              full
              ref={this.input.firstName}
              value={firstName}
              onChange={this.setFirstName}
              error={errorFirstNameMessage}
              message={errorFirstNameMessage}
            />
          </InputItem>
        </GroupInput>
        <GroupInput>
          <InputItem>
            <InputText
              label="Email"
              full
              ref={this.input.email}
              value={email}
              onChange={this.setEmail}
              error={errorEmailMessage}
              message={errorEmailMessage}
            />
          </InputItem>
          <InputItem>
            <InputText
              label="Số điện thoại"
              full
              ref={this.input.phoneNumber}
              value={phoneNumber}
              onChange={this.setPhoneNumber}
              error={errorPhoneMessage}
              message={errorPhoneMessage}
            />
          </InputItem>
        </GroupInput>
        <GroupInput>
          <InputItem>
            <SelectBox
              name="purposeToLearn"
              ref={this.input.purposeToLearn}
              placeholder="Mục đích học IELTS của bạn?"
              options={dolPurposeToLearnOptions}
              value={purposeToLearn}
              onChange={this.setPurposeToLearn}
              error={errorPurposeToLearn}
              message={errorPurposeToLearn}
            />
          </InputItem>
          <InputItem>
            <SelectBox
              ref={this.input.targetScore}
              placeholder="Mức điểm IELTS bạn hướng tới?"
              options={dolTargetScoreOptions}
              value={targetScore}
              onChange={this.setTargetScore}
              error={errorTargetScore}
              message={errorTargetScore}
            />
          </InputItem>
        </GroupInput>
        <InputItem>
          <FormControlLabel
            control={
              <DolCheckBox
                defaultChecked
                checked={needConsultant}
                color="primary"
                onChange={this.setNeedConsultant}
              />
            }
            label="Bạn có muốn DOL gọi điện tư vấn?"
            labelPlacement="end"
          />
        </InputItem>

        <InputItem style={{ marginTop: '2em' }}>
          <ErrorMessage msg={errorMessage} />
          <CustomRedBtn
            id="btn-track-register"
            size="large"
            style={{ width: '100%' }}
            type="submit"
            onClick={this.submit}
          >
            Hoàn tất đăng ký
          </CustomRedBtn>
        </InputItem>
      </Main>
    );
  }
}

FTRegisterForm.propTypes = {
  onSubmitSuccess: PropTypes.func
};

export default FTRegisterForm;
