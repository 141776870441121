import { SITE_URL } from '../apis/baseURL';

const URL_CATEGORY = [
  {
    category: 'IELTS SEO',
    url: [`${SITE_URL}/ielts`]
  },
  {
    category: 'Landing',
    url: [`${SITE_URL}/landing`, `${SITE_URL}/online`]
  },
  {
    category: 'Free test',
    url: [`${SITE_URL}/free-ielts-test`]
  },
  {
    category: 'Homepage',
    url: [`${SITE_URL}`]
  }
];

export const getSourceFromURL = url => {
  for (const item of URL_CATEGORY) {
    if (item.url.find(itemUrl => url.startsWith(itemUrl))) {
      return item.category;
    }
  }

  return url;
};
