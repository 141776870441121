import React, { memo } from 'react';

const LongArrowRight = props => {
  return (
    <svg
      width="29"
      height="14"
      viewBox="0 0 29 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.991211 7L26.9912 7" stroke="currentColor" strokeWidth="2" />
      <path
        d="M20.9229 0.999973L26.9913 7.06836L20.9229 13.1367"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

LongArrowRight.propTypes = {};

LongArrowRight.defaultProps = {};

export default memo(LongArrowRight);
