import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Title from '../MainLayout/Title';
import FTRegisterForm from '../Marketing/Online/DolRegister/RegisterFormFT';
import AnimatedImageLayout from './AnimatedImageLayout';

const Row = styled.div`
  margin-bottom: 2em;
  width: 100%;
  max-width: 600px;
`;

const FormTitle = styled(Title)`
  max-width: 600px;
`;

const FormHeaderRow = styled(Row)``;

const FTDolRegister = ({ theme, backgroundImg, onSubmitSuccess }) => {
  return (
    <AnimatedImageLayout theme={theme} backgroundImg={backgroundImg}>
      <Row>
        <FormHeaderRow>
          <FormTitle>
            Để lại thông tin trước khi xem đáp án và giải thích nhé
          </FormTitle>
        </FormHeaderRow>
        <FTRegisterForm onSubmitSuccess={onSubmitSuccess} />
      </Row>
    </AnimatedImageLayout>
  );
};

FTDolRegister.propTypes = {
  theme: PropTypes.string.isRequired,
  backgroundImg: PropTypes.shape({}),
  onSubmitSuccess: PropTypes.func
};

FTDolRegister.defaultProps = {
  onSubmitSuccess: () => {}
};

export default React.memo(FTDolRegister);
