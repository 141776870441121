import styled from 'styled-components';

import MyButton from '.';

const RedButton = styled(MyButton)`
  background: white;
  color: #d14242;
  font-weight: 500;

  span {
    margin-right: 0.5em;
  }

  svg {
    vertical-align: middle;
  }

  &:hover,
  &:focus {
    background: white;
    color: #d14242;
  }
`;

export default RedButton;
